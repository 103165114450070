import axios from 'axios';

const API_BASE_URL = '/api';

export default {
  namespaced: true,
  state: {
    URLS: {

      LOGIN: `${API_BASE_URL}/user/login`,
      LOGINLINE: `${API_BASE_URL}/user/lineLogin`,
      REFRESH_TOKEN: `${API_BASE_URL}/user/refresh`,

			GET_ACCOUNT_INFO: `${API_BASE_URL}/user/me`,

      GET_TRAINING_LESSON_LIST: `${API_BASE_URL}/lesson/list`,
      GET_LESSON_SET_LIST: `${API_BASE_URL}/happygogo/settingConfigList`,
      GET_PROFILE_LIST: `${API_BASE_URL}/profile/list`,
      GET_HAPPYGOGO_USER_LIST: `${API_BASE_URL}/happygogo/userList`,
      GET_USER_LESSON: `${API_BASE_URL}/happygogo/schedule`,
      SET_USER_LESSON: `${API_BASE_URL}/happygogo/schedule`,
      DEPLOY_USER_LESSON: `${API_BASE_URL}/happygogo/scheduleDeploy`,

      GET_DOCASO_LESSON_LIST: `${API_BASE_URL}/docasolesson/list`,
      POST_DOCASO_LESSON_REPORT: `${API_BASE_URL}/docasolesson/report`,
      GET_DOCASO_LESSON_REPORT_LIST: `${API_BASE_URL}/docasolesson/resultList`,
    }
  },
  getters: {
    axiosConfig(state, getters, rootState) {
      return {
        headers: {
          'token': rootState.auth.token,
          'Cache-Control': 'no-cache',
          // 'Content-Type': 'application/json',
        }
      };
    },
  },
  mutations: {

  },
  actions: {
    postApiPromise({getters}, {url, data}) {
      return new Promise(function(resolve, reject) {
        const postData = data? data: {};
        axios.post(url, postData, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    postUploadFilePromise({getters}, {url, filename, file}) {
      const formData = new FormData();
      formData.append(filename, file);
      // const axiosConfig = {
      //   headers: {
      //     'token': getters.axiosConfig.headers.token,
      //     'Cache-Control': 'no-cache',
      //     'Content-Type': 'multipart/form-data',
      //   }
      // };
      const axiosConfig = JSON.parse(JSON.stringify(getters.axiosConfig)); //使用深拷貝, 使其不影響其他引用getters.axiosConfig的未知錯誤
      axiosConfig.headers['Content-Type'] = 'multipart/form-data';
      return new Promise(function(resolve, reject) {
        axios.post(url, formData, axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },
    getApiPromise({getters}, {url}) {
      return new Promise(function(resolve, reject) {
        axios.get(url, getters.axiosConfig).then(function(d) {
          if (d.status === 200 && d.data.status === 'ok') {
            resolve(d.data.data);
          } else {
            reject(d.data.reason);
          }
        }).catch(function(e) {
          reject(e);
        });
      });
    },

    /* */


    postLoginPromise({dispatch, state}, {email, password}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LOGIN,
        data: {email, password}
      });
    },

    postLoginLinePromise({dispatch, state}, {code, callbackUri}) {
      return dispatch('postApiPromise', {
        url: state.URLS.LOGINLINE,
        data: {code, callbackUri}
      });
    },

    postRefreshTokenPromise({dispatch, state}, token) {
      return dispatch('postApiPromise', {
        url: state.URLS.REFRESH_TOKEN,
        data: {token}
      });
    },

    getAccountInfoPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_ACCOUNT_INFO,
      });
    },



    /* 個案相關 */
    
    getProfileListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_PROFILE_LIST,
      });
    },

    /* 客製化課程相關 */

    getDocasoLessonListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_DOCASO_LESSON_LIST,
      });
    },

    postDocasoLessonReportPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.POST_DOCASO_LESSON_REPORT,
        data,
      });
    },

    getDocasoLessonReportListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_DOCASO_LESSON_REPORT_LIST,
      });
    },
    
    /* happy go go 相關 */

    getTrainingLessonListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_TRAINING_LESSON_LIST,
      });
    },
    
    getLessonSetListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_LESSON_SET_LIST,
      });
    },
    getHappyGoGoUserListPromise({dispatch, state}) {
      return dispatch('getApiPromise', {
        url: state.URLS.GET_HAPPYGOGO_USER_LIST,
      });
    },

    getUserLessonPromise({dispatch, state}, id) {
      return dispatch('getApiPromise', {
        url: `${state.URLS.GET_USER_LESSON}/${id}`,
      });
    },

    postUserLessonPromise({dispatch, state}, data) {
      return dispatch('postApiPromise', {
        url: state.URLS.SET_USER_LESSON,
        data,
      });
    },

    postDeployUserLessonPromise({dispatch, state}, userKey) {
      return dispatch('postApiPromise', {
        url: state.URLS.DEPLOY_USER_LESSON,
        data: {
          userKey,
        },
      });
    },
  },
}
